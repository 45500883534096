import Wheel from '../../components/Wheel'

export default function WheelSimHelper({initial, setInitial}){
    let albums = 
        {
            "error": false,
            "rows": [
                {
                    "width": "100",
                    "percent": "0.21804511278195488722",
                    "album_id": 1559,
                    "score": "29",
                    "album_rank": "1",
                    "MEMBER": 6,
                    "SUBGENRE": "Alternative R&B",
                    "ALBUM": "MM..FOOD",
                    "ARTIST": "Frank Ocean",
                    "TRACKS": 17,
                    "IMAGE": "https://i.scdn.co/image/ab67616d00001e02c5649add07ed3720be9d5526",
                    "POPULARITY": 91,
                    "TIMESTAMP": "2024-09-24T23:51:39.123Z",
                    "NOMINATION_ID": 1559,
                    "DESCRIPTION": "Fine, I’ll do it myself. ",
                    "SAMPLE": "2ZWlPOoWh0626oTaHrnl2a",
                    "SPOTIFY_ID": "3mH6qwIy9crq0I9YQbOuDf",
                    "RELEASE_DATE": 2016,
                    "DISCUSSION_ID": 130
                },
                {
                    "width": "86",
                    "percent": "0.18796992481203007519",
                    "album_id": 1555,
                    "score": "25",
                    "album_rank": "2",
                    "MEMBER": 298,
                    "SUBGENRE": "contemporary, alternative, neo soul",
                    "ALBUM": "channel ORANGE",
                    "ARTIST": "Frank Ocean",
                    "TRACKS": 17,
                    "IMAGE": "https://i.scdn.co/image/ab67616d00001e027aede4855f6d0d738012e2e5",
                    "POPULARITY": 88,
                    "TIMESTAMP": "2024-09-24T20:00:02.557Z",
                    "NOMINATION_ID": 1555,
                    "DESCRIPTION": "more bangers than blonde dont @ me",
                    "SAMPLE": "1fOkmYW3ZFkkjIdOZSf596",
                    "SPOTIFY_ID": "392p3shh2jkxUxY2VHvlH8",
                    "RELEASE_DATE": 2012,
                    "DISCUSSION_ID": 130
                },
                {
                    "width": "72",
                    "percent": "0.15789473684210526316",
                    "album_id": 1535,
                    "score": "21",
                    "album_rank": "3",
                    "MEMBER": 63,
                    "SUBGENRE": "Contemporary R&B",
                    "ALBUM": "Love Deluxe",
                    "ARTIST": "Sade",
                    "TRACKS": 9,
                    "IMAGE": "https://i.scdn.co/image/ab67616d00001e02ee65bbd54f993b5f01d5c511",
                    "POPULARITY": 74,
                    "TIMESTAMP": "2024-09-24T03:01:44.504Z",
                    "NOMINATION_ID": 1535,
                    "DESCRIPTION": "bad bitches rise up",
                    "SAMPLE": "1oaaSrDJimABpOdCEbw2DJ",
                    "SPOTIFY_ID": "2PfGKHtqEX58bHtkQxJnWG",
                    "RELEASE_DATE": 1992,
                    "DISCUSSION_ID": 130
                },
                {
                    "width": "69",
                    "percent": "0.15037593984962406015",
                    "album_id": 1532,
                    "score": "20",
                    "album_rank": "4",
                    "MEMBER": 304,
                    "SUBGENRE": "R&B, Soul, Funk",
                    "ALBUM": "\"Awaken, My Love!\"",
                    "ARTIST": "Childish Gambino",
                    "TRACKS": 11,
                    "IMAGE": "https://i.scdn.co/image/ab67616d00001e027582716b3666a5235d5af4ea",
                    "POPULARITY": 79,
                    "TIMESTAMP": "2024-09-24T03:00:44.797Z",
                    "NOMINATION_ID": 1532,
                    "DESCRIPTION": null,
                    "SAMPLE": "4AFsRbaLKRWo3dDtjDFA2V",
                    "SPOTIFY_ID": "7caGY3YPOchIO8xLvTKWN4",
                    "RELEASE_DATE": 2016,
                    "DISCUSSION_ID": 130
                },
                {
                    "width": "69",
                    "percent": "0.15037593984962406015",
                    "album_id": 1547,
                    "score": "20",
                    "album_rank": "4",
                    "MEMBER": 496,
                    "SUBGENRE": "neo soul, gospel",
                    "ALBUM": "Freudian",
                    "ARTIST": "Daniel Caesar",
                    "TRACKS": 10,
                    "IMAGE": "https://i.scdn.co/image/ab67616d00001e023138f891f3075c9c5d944037",
                    "POPULARITY": 86,
                    "TIMESTAMP": "2024-09-24T04:18:11.440Z",
                    "NOMINATION_ID": 1547,
                    "DESCRIPTION": "Freudian is Daniel Caesar’s debut studio album released August 25, 2017. This album is actually peak music",
                    "SAMPLE": "1RMJOxR6GRPsBHL8qeC2ux",
                    "SPOTIFY_ID": "3xybjP7r2VsWzwvDQipdM0",
                    "RELEASE_DATE": 2017,
                    "DISCUSSION_ID": 130
                },
                {
                    "width": "62",
                    "percent": "0.13533834586466165414",
                    "album_id": 1536,
                    "score": "18",
                    "album_rank": "6",
                    "MEMBER": 153,
                    "SUBGENRE": "soul, funk, hip hop",
                    "ALBUM": "Drunk",
                    "ARTIST": "Thundercat",
                    "TRACKS": 23,
                    "IMAGE": "https://i.scdn.co/image/ab67616d00001e0285c5e6c686ced3e43bae2748",
                    "POPULARITY": 70,
                    "TIMESTAMP": "2024-09-24T03:02:23.535Z",
                    "NOMINATION_ID": 1536,
                    "DESCRIPTION": null,
                    "SAMPLE": "3QRQwnaENDNslrc5tNUGgE",
                    "SPOTIFY_ID": "7vHBQDqwzB7uDvoE5bncMM",
                    "RELEASE_DATE": 2017,
                    "DISCUSSION_ID": 130
                }
            ]
        };
      let passInitial = {
        "time": "00:00:00",
        "error": false,
        "thisGenre": "r&b",
        "phase": 2,
        "countdown": false,
        "timestamp": "2024-09-30 20:00:00",
        "location": "ARCC 207",
        "ratingOpen": false,
        "google": "910487911633-p4ikan79or5pinq51pbhhhluah8t4fjd.apps.googleusercontent.com"
    };
    return (
        <Wheel albums={albums.rows} initial={passInitial} setInitial={setInitial} />
    )
}