import { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { Typography, Card, Stack, Box, Grid, ClickAwayListener } from '@mui/material'
import { PieChart, pieArcLabelClasses } from '@mui/x-charts';

import WheelSimHelper from './selection/WheelSimHelper'

function WheelSim(){
    const [initial, setInitial] = useState({phase: 'countdown'}) // stores initial data (phase and google authentication key)
    return(
        <WheelSimHelper initial={initial} setInitial={setInitial} />
    )
}

export default WheelSim;